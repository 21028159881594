<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">{{ 'Login'|trans }}</h5>
          </div>

          <div class="card-padding">
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :placeholder="'Email'|trans"
              v-model="email"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-text-field
              hide-details
              type="password"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :placeholder="'Password'|trans"
              v-model="password"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
                text-white
              "
              color="#5e72e4"
              small
              @click="handleSubmit"
              :loading="isLoading"
              >{{ 'Login'|trans }}

            </v-btn>
            <p class="text-sm text-body mt-3 mb-0">
              {{ 'Forgot your password ?'|trans }}
              <router-link
                  :to="{ name: 'request-password-reset' }"
                  class="
                    text-default text-gradient-default text-decoration-none
                    font-weight-bold
                  "
              >
                {{ 'Change it.'|trans }}
              </router-link>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      localAlert: null,
    };
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({ name: "backoffice" });
    }
  },
  computed: {
    canSubmit: function () {
      return this.password.length > 0 && this.email.length > 0;
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.localAlert = null;
      //let $this = this;
      if (!this.canSubmit) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Fill in both email and password!"),
        };
        return false;
      }

      this.isLoading = true;

      let data = {
        email: this.email,
        password: this.password,
      };

      this.$store
        .dispatch("AUTH_REQUEST", data)
        .then(() => {
          this.$router.push({ name: "backoffice" });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message
              ? error.message
              : this.$translator.trans("Authentication is not possible!"),
          };
          this.isLoading = false;
        });
    },
  },
};
</script>
